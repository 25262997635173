.module-view-mask-wrapper {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: transparent;
    left: 0;
    top: 0;
    display: none;
    visibility: hidden;
}
.module-view-mask-wrapper.show {
    display: block;
    visibility: visible;
}
.module-view-mask-wrapper>.mask-content-wrap{
    height: 100%;
    width: 100%;
}
