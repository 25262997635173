* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 16px;
}
@media (min-width: 240px){
    html{
        font-size: 12px;
    }
}
@media (min-width: 320px){
    html{
        font-size: 16px;
    }
}
html.m_240{
    font-size: 12px;
}
body {
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
a {-webkit-tap-highlight-color:transparent;text-decoration: none;color: inherit;}
li {list-style: none;}
img {-webkit-tap-highlight-color:transparent;max-width: 100%;}
:focus {
    outline:0;
    -webkit-tap-highlight-color:transparent
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.marquee {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-animation: marquee 10s linear infinite;
            animation: marquee 10s linear infinite;
    display: inline-block;
    line-height: inherit;
}

@-webkit-keyframes marquee {
    0% { -webkit-transform: translateX(100%); transform: translateX(100%); }
    100% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}

@keyframes marquee {
    0% { -webkit-transform: translateX(100%); transform: translateX(100%); }
    100% { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
}