.mv-point_tip {
    width:100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.mv-point_tip .inner-wrap{
    color: #fff;
    background-color: #333;
    text-align: center;
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 2rem;
}
.mv-point_tip .txt-wrap{
    font-size: 1.8rem;
    line-height: 1;
    padding-bottom: 0.5rem;
}
.mv-point_tip .txt-wrap .t-point{
    font-size: 3rem;
    padding: 0 2px;
    color: #ffa407;
    font-style: normal;
}
.mv-point_tip .icon-wrap{
    padding: 1rem;
    width: auto;
    display: inline-block;
    border-radius: 50%;
    margin-top: -2.75rem;
    background-color: #333;
}
.mv-point_tip .icon-wrap .point-icon{
    font-size: 4rem;
}
