.app-content-wrap {
    height: 100%;
    width: 100%;
    background-color:#444;
    color: white;
    -webkit-user-select: none;
            user-select: none;
}
.app-content-wrap p{margin: 0;}
.trans{
    transition: all .1s;
}
